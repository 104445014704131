<template>
	<div class="wrap">
		<el-page-header @back="$router.back()" content="会员资料" class="margin-bottom margin-top-xs"></el-page-header>
		<el-divider content-position="left">基本信息</el-divider>
		<div v-if="form.id">
			<div class="form_box">
				<el-form ref="form" :model="form" label-width="100px" class="flex flex-wrap">
					<el-form-item label="姓名">
						<div class="form-item-box">
							<el-input v-model="form.real_name" placeholder="请输入会员姓名" readonly></el-input>
						</div>
					</el-form-item>
					<el-form-item label="会员性别">
						<div class="form-item-box">
							<el-radio v-model="form.gender" :label="0" :disabled="form.gender != 0">女</el-radio>
							<el-radio v-model="form.gender" :label="1" :disabled="form.gender != 1">男</el-radio>
						</div>
					</el-form-item>
					<el-form-item label="手机号码">
						<div class="form-item-box">
							<el-input v-model="form.phone" placeholder="请输入手机号码" readonly></el-input>
						</div>
					</el-form-item>
					<el-form-item label="籍贯">
						<div class="form-item-box">
							<el-input v-model="form.native_place" placeholder="请输入会员籍贯" readonly></el-input>
						</div>
					</el-form-item>
					<el-form-item label="出生日期" prop="birthday">
						<div class="form-item-box">
							<el-date-picker v-model="form.birthday" type="date" placeholder="选择生日" readonly
								format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" style="width: 100%;">
							</el-date-picker>
						</div>
					</el-form-item>
					<el-form-item label="生肖">
						<div class="form-item-box">
							<el-input v-model="form.zodiac" placeholder="请输入生肖" readonly></el-input>
						</div>
					</el-form-item>
					<el-form-item label="血型">
						<div class="form-item-box">
							<el-input v-model="form.blood_type" placeholder="请输入血型" readonly></el-input>
						</div>
					</el-form-item>
					<el-form-item label="星座">
						<div class="form-item-box">
							<el-input v-model="form.constellation" placeholder="请输入星座" readonly></el-input>
						</div>
					</el-form-item>
					<el-form-item label="身高">
						<div class="form-item-box">
							<el-input v-model="form.height" placeholder="请输入身高" readonly></el-input>
						</div>
					</el-form-item>
					<el-form-item label="体重">
						<div class="form-item-box">
							<el-input v-model="form.weight" placeholder="请输入体重" readonly></el-input>
						</div>
					</el-form-item>
					<el-form-item label="婚姻状况">
						<div class="form-item-box">
							<el-input v-model="form.marriage" placeholder="请输入婚姻状况" readonly></el-input>
						</div>
					</el-form-item>
					<el-form-item label="子女">
						<div class="form-item-box">
							<el-input v-model="form.children" placeholder="请输入子女情况" readonly></el-input>
						</div>
					</el-form-item>
					<el-form-item label="所在地区" prop="location">
						<div class="form-item-box">
							<el-cascader v-model="form.location" :options="xzqhOptions" @change="locationChange"
								clearable style="width: 100%;" readonly>
							</el-cascader>
						</div>
					</el-form-item>
					<el-form-item label="通讯地址">
						<div class="form-item-box">
							<el-input v-model="form.postal_address" placeholder="请输入通讯地址" readonly></el-input>
						</div>
					</el-form-item>
					<el-form-item label="电话">
						<div class="form-item-box">
							<el-input v-model="form.tel" placeholder="请输入电话" readonly></el-input>
						</div>
					</el-form-item>
					<el-form-item label="邮编">
						<div class="form-item-box">
							<el-input v-model="form.postcode" placeholder="请输入邮编" readonly></el-input>
						</div>
					</el-form-item>
					<el-form-item label="文化程度">
						<div class="form-item-box">
							<el-input v-model="form.culture" placeholder="请输入文化程度" readonly></el-input>
						</div>
					</el-form-item>
					<el-form-item label="职业">
						<div class="form-item-box">
							<el-input v-model="form.occupation" placeholder="请输入职业" readonly></el-input>
						</div>
					</el-form-item>
					<el-form-item label="身份证号">
						<div class="form-item-box">
							<el-input v-model="form.id_number" placeholder="请输入身份证号" readonly></el-input>
						</div>
					</el-form-item>
					<el-form-item label="登记时间">
						<div class="form-item-box">
							<el-input v-model="form.created_at" readonly></el-input>
						</div>
					</el-form-item>
				</el-form>
			</div>
			<el-divider content-position="left">详细资料</el-divider>
			<div class="form_box">
				<el-form ref="form" :model="form" label-width="100px">
					<el-form-item label="如何知道本店">
						<el-checkbox-group v-model="form.know_shop">
							<el-checkbox label="朋友介绍" name="know_shop" :disabled="form.motion != '朋友介绍'"></el-checkbox>
							<el-checkbox label="宣传单张" name="know_shop" :disabled="form.motion != '宣传单张'"></el-checkbox>
							<el-checkbox label="杂志广告" name="know_shop" :disabled="form.motion != '杂志广告'"></el-checkbox>
							<el-checkbox label="报纸广告" name="know_shop" :disabled="form.motion != '报纸广告'"></el-checkbox>
							<el-checkbox label="电视广告" name="know_shop" :disabled="form.motion != '电视广告'"></el-checkbox>
							<el-checkbox label="路过偶然经过" name="know_shop" :disabled="form.motion != '路过偶然经过'"></el-checkbox>
							<el-checkbox label="参加活动(联谊会)" name="know_shop" :disabled="form.motion != '参加活动(联谊会)'"></el-checkbox>
							<el-checkbox label="其他" name="know_shop" :disabled="form.motion != '其他'"></el-checkbox>
							<el-input v-model="form.know_shop_other" style="width: 200px;" class="margin-left-sm" readonly
								placeholder="请输入其他方式" :disabled="Boolean(!~form.know_shop.indexOf('其他'))"></el-input>
						</el-checkbox-group>

					</el-form-item>
					<el-form-item label="居家护理习惯">
						<el-checkbox-group v-model="form.home_care">
							<el-checkbox label="洗面奶" name="home_care" :disabled="form.motion != '洗面奶'"></el-checkbox>
							<el-checkbox label="面膜" name="home_care" :disabled="form.motion != '面膜'"></el-checkbox>
							<el-checkbox label="爽肤水" name="home_care" :disabled="form.motion != '爽肤水'"></el-checkbox>
							<el-checkbox label="面霜" name="home_care" :disabled="form.motion != '面霜'"></el-checkbox>
							<el-checkbox label="精华素" name="home_care" :disabled="form.motion != '精华素'"></el-checkbox>
							<el-checkbox label="眼霜" name="home_care" :disabled="form.motion != '眼霜'"></el-checkbox>
						</el-checkbox-group>
					</el-form-item>
					<el-form-item label="专业护理习惯">
						<el-radio-group v-model="form.major_care">
							<el-radio label="经常" name="major_care" :disabled="form.motion != '经常'"></el-radio>
							<el-radio label="偶尔" name="major_care" :disabled="form.motion != '偶尔'"></el-radio>
							<el-radio label="从来没有" name="major_care" :disabled="form.motion != '从来没有'"></el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="工作时间">
						<el-time-select v-model="form.workStart" readonly
							:picker-options="{start: '00:00',step: '00:10',end: '23:50'}" placeholder="选择时间">
						</el-time-select>
						至
						<el-time-select v-model="form.workEnd" readonly
							:picker-options="{start: '00:00',step: '00:10',end: '23:50'}" placeholder="选择时间">
						</el-time-select>
					</el-form-item>
					<el-form-item label="工作劳动量">
						<el-radio-group v-model="form.work_load">
							<el-radio label="轻度" name="work_load" :disabled="form.motion != '轻度'"></el-radio>
							<el-radio label="中度" name="work_load" :disabled="form.motion != '中度'"></el-radio>
							<el-radio label="重度" name="work_load" :disabled="form.motion != '重度'"></el-radio>
							<el-radio label="极重度" name="work_load" :disabled="form.motion != '极重度'"></el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="工作压力">
						<el-radio-group v-model="form.working_pressure">
							<el-radio label="很大" name="working_pressure" :disabled="form.motion != '很大'"></el-radio>
							<el-radio label="较大" name="working_pressure" :disabled="form.motion != '较大'"></el-radio>
							<el-radio label="一般" name="working_pressure" :disabled="form.motion != '一般'"></el-radio>
							<el-radio label="较小" name="working_pressure" :disabled="form.motion != '较小'"></el-radio>
							<el-radio label="几乎没有" name="working_pressure" :disabled="form.motion != '几乎没有'"></el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="空闲时间">
						<el-input v-model="form.free_time" placeholder="请输入空闲时间" readonly></el-input>
					</el-form-item>
					<el-form-item label="工作日">
						<el-checkbox-group v-model="form.weekdays">
							<el-checkbox label="周一" name="weekdays" :disabled="form.motion != '周一'"></el-checkbox>
							<el-checkbox label="周二" name="weekdays" :disabled="form.motion != '周二'"></el-checkbox>
							<el-checkbox label="周三" name="weekdays" :disabled="form.motion != '周三'"></el-checkbox>
							<el-checkbox label="周四" name="weekdays" :disabled="form.motion != '周四'"></el-checkbox>
							<el-checkbox label="周五" name="weekdays" :disabled="form.motion != '周五'"></el-checkbox>
							<el-checkbox label="周六" name="weekdays" :disabled="form.motion != '周六'"></el-checkbox>
							<el-checkbox label="周日" name="weekdays" :disabled="form.motion != '周日'"></el-checkbox>
						</el-checkbox-group>
					</el-form-item>
					<el-form-item label="休息日">
						<el-checkbox-group v-model="form.rest_day">
							<el-checkbox label="周一" name="rest_day" :disabled="form.motion != '周一'"></el-checkbox>
							<el-checkbox label="周二" name="rest_day" :disabled="form.motion != '周二'"></el-checkbox>
							<el-checkbox label="周三" name="rest_day" :disabled="form.motion != '周三'"></el-checkbox>
							<el-checkbox label="周四" name="rest_day" :disabled="form.motion != '周四'"></el-checkbox>
							<el-checkbox label="周五" name="rest_day" :disabled="form.motion != '周五'"></el-checkbox>
							<el-checkbox label="周六" name="rest_day" :disabled="form.motion != '周六'"></el-checkbox>
							<el-checkbox label="周日" name="rest_day" :disabled="form.motion != '周日'"></el-checkbox>
						</el-checkbox-group>
					</el-form-item>
					<el-form-item label="睡眠时间">
						<el-radio-group v-model="form.sleep_time">
							<el-radio label="6小时以下" name="sleep_time" :disabled="form.motion != '6小时以下'"></el-radio>
							<el-radio label="6-7小时" name="sleep_time" :disabled="form.motion != '6-7小时'"></el-radio>
							<el-radio label="7-8小时" name="sleep_time" :disabled="form.motion != '7-8小时'"></el-radio>
							<el-radio label="8-10小时" name="sleep_time" :disabled="form.motion != '8-10小时'"></el-radio>
							<el-radio label="10小时以上" name="sleep_time" :disabled="form.motion != '10小时以上'"></el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="睡眠质量">
						<el-radio-group v-model="form.sleep_quality">
							<el-radio label="很好" name="sleep_quality" :disabled="form.motion != '很好'"></el-radio>
							<el-radio label="较好" name="sleep_quality" :disabled="form.motion != '较好'"></el-radio>
							<el-radio label="一般" name="sleep_quality" :disabled="form.motion != '一般'"></el-radio>
							<el-radio label="较差" name="sleep_quality" :disabled="form.motion != '较差'"></el-radio>
							<el-radio label="很差" name="sleep_quality" :disabled="form.motion != '很差'"></el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="饮水习惯">
						<el-radio-group v-model="form.water_habit">
							<el-radio label="很多" name="water_habit" :disabled="form.motion != '很多'"></el-radio>
							<el-radio label="比较多" name="water_habit" :disabled="form.motion != '比较多'"></el-radio>
							<el-radio label="一般" name="water_habit" :disabled="form.motion != '一般'"></el-radio>
							<el-radio label="比较少" name="water_habit" :disabled="form.motion != '比较少'"></el-radio>
							<el-radio label="很少" name="water_habit" :disabled="form.motion != '很少'"></el-radio>
						</el-radio-group>
						<el-input v-model="form.water_amount" style="width: 350px;" placeholder="请输入每日饮水量" readonly><template
								slot="prepend">平均每日饮水量</template><template slot="append">CC</template></el-input>
					</el-form-item>
					<el-form-item label="食物口味">
						<el-radio-group v-model="form.food_taste">
							<el-radio label="偏辣" name="food_taste" :disabled="form.motion != '偏辣'"></el-radio>
							<el-radio label="偏甜" name="food_taste" :disabled="form.motion != '偏甜'"></el-radio>
							<el-radio label="偏酸" name="food_taste" :disabled="form.motion != '偏酸'"></el-radio>
							<el-radio label="偏咸" name="food_taste" :disabled="form.motion != '偏咸'"></el-radio>
							<el-radio label="偏淡" name="food_taste" :disabled="form.motion != '偏淡'"></el-radio>
							<el-radio label="适中" name="food_taste" :disabled="form.motion != '适中'"></el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="饮食喜好">
						<el-checkbox-group v-model="form.diet_like">
							<el-checkbox label="甜食" name="diet_like" :disabled="form.motion != '甜食'"></el-checkbox>
							<el-checkbox label="油炸" name="diet_like" :disabled="form.motion != '油炸'"></el-checkbox>
							<el-checkbox label="酸" name="diet_like" :disabled="form.motion != '酸'"></el-checkbox>
							<el-checkbox label="咸" name="diet_like" :disabled="form.motion != '咸'"></el-checkbox>
							<el-checkbox label="辣" name="diet_like" :disabled="form.motion != '辣'"></el-checkbox>
							<el-checkbox label="饮料" name="diet_like" :disabled="form.motion != '饮料'"></el-checkbox>
							<el-checkbox label="白开水" name="diet_like" :disabled="form.motion != '白开水'"></el-checkbox>
							<el-checkbox label="水果" name="diet_like" :disabled="form.motion != '水果'"></el-checkbox>
							<el-checkbox label="海产" name="diet_like" :disabled="form.motion != '海产'"></el-checkbox>
							<el-checkbox label="肉类" name="diet_like" :disabled="form.motion != '肉类'"></el-checkbox>
							<el-checkbox label="零食" name="diet_like" :disabled="form.motion != '零食'"></el-checkbox>
							<el-checkbox label="蔬菜" name="diet_like" :disabled="form.motion != '蔬菜'"></el-checkbox>
							<el-checkbox label="其他" name="diet_like" :disabled="form.motion != '其他'"></el-checkbox>
							<el-input v-model="form.diet_like_other" style="width: 200px;" class="margin-left-sm"
								placeholder="请输入其他方式" :disabled="Boolean(!~form.diet_like.indexOf('其他'))" readonly></el-input>
						</el-checkbox-group>
					</el-form-item>
					<el-form-item label="饮酒">
						<el-radio-group v-model="form.drink_wine">
							<el-radio label="时常" name="drink_wine" :disabled="form.motion != '时常'"></el-radio>
							<el-radio label="偶尔" name="drink_wine" :disabled="form.motion != '偶尔'"></el-radio>
							<el-radio label="没有" name="drink_wine" :disabled="form.motion != '没有'"></el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="抽烟">
						<el-radio-group v-model="form.smoking">
							<el-radio label="时常" name="smoking" :disabled="form.motion != '时常'"></el-radio>
							<el-radio label="偶尔" name="smoking" :disabled="form.motion != '偶尔'"></el-radio>
							<el-radio label="没有" name="smoking" :disabled="form.motion != '没有'"></el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="运动">
						<el-radio-group v-model="form.motion">
							<el-radio label="时常" name="motion" :disabled="form.motion != '时常'"></el-radio>
							<el-radio label="偶尔" name="motion" :disabled="form.motion != '偶尔'"></el-radio>
							<el-radio label="一周三次" name="motion" :disabled="form.motion != '一周三次'"></el-radio>
							<el-radio label="几乎没有" name="motion" :disabled="form.motion != '几乎没有'"></el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="备注">
						<el-input v-model="form.remark" type="textarea" :autosize="{ minRows: 4}" readonly></el-input>
					</el-form-item>
					
				</el-form>
			</div>
		</div>
	</div>
</template>


<script>
	// 引入公用表单验证
	import rules from "../../../common/common.rules";
	// 行政区划npm版本
	import ChinaLocation from "china-location";
	import list from "china-location/dist/location.json";
	export default {
		data() {
			return {
				rules,
				form: {}
			};
		},
		computed: {
			xzqhOptions() {
				// return xzqh.addrData();
				let chinaLocation = new ChinaLocation(list);
				// console.log(list)
				return this.formatLocation(chinaLocation.locationData);
			},
			submitForm(){
				let sf = this.$util.deepClone(this.form)
				sf.province = this.form.location[0]
				sf.city = this.form.location[1]
				sf.district = this.form.location[2]
				sf.know_shop = JSON.stringify(sf.know_shop);
				sf.home_care = JSON.stringify(sf.home_care);
				sf.weekdays = JSON.stringify(sf.weekdays);
				sf.rest_day = JSON.stringify(sf.rest_day);
				sf.food_taste = JSON.stringify(sf.food_taste);
				sf.diet_like = JSON.stringify(sf.diet_like);
				// 生活习惯 工作时间
				sf.habits_customs = JSON.stringify(sf.habits_customs);
				sf.workStart = JSON.stringify(sf.habits_customs);
				sf.workEnd = JSON.stringify(sf.habits_customs);
				return sf;
			}
		},
		mounted() {
			// 获取当前编辑的会员信息
			if (this.$route.params.id) {
				this.$api.table.getMemberById({
					id: this.$route.params.id
				}).then(res => {
					// 如何知道门店
					res.data.know_shop = res.data.know_shop ? JSON.parse(res.data.know_shop) : [];
					// 家居护理
					res.data.home_care = res.data.home_care ? JSON.parse(res.data.home_care) : [];
					// 工作日
					res.data.weekdays = res.data.weekdays ? JSON.parse(res.data.weekdays) : [];
					// 休息日
					res.data.rest_day = res.data.rest_day ? JSON.parse(res.data.rest_day) : [];
					// 食物口味
					res.data.food_taste = res.data.food_taste ? JSON.parse(res.data.food_taste) : [];
					// 饮食喜好
					res.data.diet_like = res.data.diet_like ? JSON.parse(res.data.diet_like) : [];
					// 生活习惯 工作时间
					res.data.habits_customs = res.data.habits_customs ? JSON.parse(res.data.habits_customs) : [];
					res.data.workStart = res.data.habits_customs ? res.data.habits_customs[0] : '';
					res.data.workEnd = res.data.habits_customs ? res.data.habits_customs[1] : '';
					// 地区
					res.data.location = [res.data.province, res.data.city, res.data.district]
					this.form = res.data;
				});
			}
		},
		methods: {
			// 所在地区改变事件
			locationChange(val) {
				console.log(val)
			},
			// 格式化省市区
			formatLocation(addr) {
				let x = [];
				for (let key in addr) {
					let obj = {};
					if (addr[key].cities) {
						// obj.value = key;
						obj.value = addr[key].name;
						obj.label = addr[key].name;
						obj.children = this.formatLocation(addr[key].cities);
					} else if (addr[key].districts) {
						// obj.value = key;
						obj.value = addr[key].name;
						obj.label = addr[key].name;
						obj.children = this.formatLocation(addr[key].districts);
					} else {
						// obj.value = key;
						obj.value = addr[key];
						obj.label = addr[key];
					}
					x.push(obj);
				}
				return x;
			},
			
		}
	};
</script>
<style scoped lang="scss">
	.form_box {
		max-width: 800px;
	}

	.form-item-box {
		width: 300px
	}
</style>
